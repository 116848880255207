<template>
  <div class="login">
	<div class="content">
		<div class="title">
		</div>
		<div class="input-container">
			<div class="input-box" v-if="loginPage">
				<div class="input-box-top">
				    <p class="loginP p2">找 回 密 码</p>
				    <el-form :model="registerUser" :rules="rulesregister" ref="registerForm" class="loginForm re">
				    	<div class="titleTip">请填写您需要找回的帐号(邮箱)</div>
						<el-form-item prop="email">
				    		<span class="el-icon-message icon"></span>
				    		<el-input v-model="registerUser.email" placeholder="请输入邮箱" class="verifi"></el-input>
				    	</el-form-item>
				    	<el-form-item prop="verificationCode">
				    		<span class="el-icon-key icon"></span>
							<span class="icon2 icon3" v-if="countdown==0" @click="sendCode()">发送验证码</span>
							<span class="icon2 icon3 icon4" v-else>{{countdown}} s</span>
				    		<el-input v-model="registerUser.verificationCode" placeholder="验证码"></el-input>
				    	</el-form-item>
				    	<el-form-item class="form">
				    		<el-button type="primary" @click="VerifyCode('registerForm')" class="submit_btn">确 认</el-button>
				    	</el-form-item>
				    </el-form>
				</div>
			</div>
			<div class="input-box" v-else>
				<div class="input-box-top">
				    <p class="loginP p2">找 回 密 码</p>
				    <el-form :model="registerUser" :rules="rulesregister" ref="registerForm" class="loginForm re">
						<el-form-item prop="pwd">
				    		<span class="el-icon-lock icon"></span>
				    		<el-input type="password" v-model="registerUser.pwd" placeholder="新密码"></el-input>
				    	</el-form-item>
				    	<el-form-item prop="surePassword">
				    		<span class="el-icon-lock icon"></span>
				    		<el-input type="password" v-model="registerUser.surePassword" placeholder="确认密码"></el-input>
				    	</el-form-item>
				    	<el-form-item class="form">
				    		<el-button type="primary" @click="update('registerForm')" class="submit_btn">确 认</el-button>
				    	</el-form-item>
				    </el-form>
				</div>
			</div>
		</div>
		<div class="foot-container">
			<p><a>鹦鹉塔科技&nbsp;|&nbsp;</a><a href="https://beian.miit.gov.cn">沪ICP备2021021283号-1</a></p>
		</div>
	</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      loginPage: true,
      countdown: 0,
      registerUser: {
        email: '',
        verificationCode: '',
        pwd: '',
        surePassword: ''
      },
      rulesregister: {
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' }
        ],
        verificationCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        surePassword: [
          { required: true, message: '请确认密码', trigger: 'blur' }
        ]

      },
      isDownLoad: false
    }
  },
  mounted () {
  },
  methods: {
    changePage (formName) {
      this.loginPage = !this.loginPage
      this.$refs[formName].resetFields()
    },
    sendCode () {
      const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
      const value = this.registerUser.email
      if (value == '' || value == undefined || value == null) {
        this.$message({ type: 'warning', message: '请输入邮箱地址' })
        return
      } else {
        if (!reg.test(value)) {
			    this.$message({ type: 'error', message: '请输入正确的邮箱地址' })
			    return
        }
      }
      this.countdown = 60
      var formDate = new FormData()
      formDate.append('recipientArry[]', this.registerUser.email)
      formDate.append('mailTitle', '找回密码')
      var _this = this
      var time = window.setInterval(function () {
        if (_this.countdown === 0) {
          window.clearInterval(time)
        } else {
          _this.countdown -= 1
        }
      }, 1000)
      this.$axios.post('/api/Auth/PostEmails', formDate).then(res => {
        this.$message({
          type: 'success',
          message: '验证码发送成功'
        })
      })
    },
    VerifyCode (formName) {
      console.log(formName)
      var userName = this.registerUser.email
      var code = this.registerUser.verificationCode
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.registerUser)
			    this.$axios.get('/api/User/VerifyCode?userName=' + userName + '&code=' + code).then(res => {
				    this.loginPage = false
			    })
		    }
      })
    },
    update (formName) {
      var userName = this.registerUser.email
      var password = this.registerUser.pwd
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.registerUser.pwd != this.registerUser.surePassword) {
				    this.$message({
					    type: 'warning',
					    message: '新密码和确认密码不一致'
            })
				  return
			  }
			    this.$axios.get('/api/User/UpdateUserForgetPassword?userName=' + userName + '&password=' + password).then(res => {
				    this.$message({
              type: 'success',
              message: res
            })
				    this.$router.push('/login')
			    })
		    }
      })
    },
    ...mapMutations('user', {
      initUserInfo: 'initUserInfo'
    })
  }
}
</script>

<style  lang="less">
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /*定义父元素的元素垂直居中*/
  justify-content: center; /*定义子元素的里的元素水平居中*/
  background: url(../assets/Login/bg.png);
  background-size: 100% 100%;
  .el-button:focus, .el-button:hover{
  	color: #fff;
  }
  .el-button{
  	border: none;
  }
  .content {
  	display: flex;
  	flex-direction: column;
  	flex-wrap: nowrap;
  	justify-content: space-between;
  	align-items: center;
  	margin: auto;
  	box-sizing: border-box;
  	height: 100vh;
  	// min-height: 960px;
  	width: 870px;
  	padding: 90px 0 100px;

  	// .title {
  	// 	width: 580px;
  	// 	height: 60px;
  	// 	background: url(../assets/Login/title.png) no-repeat;
  	// 	background-size: cover;
  	// }

  	.input-container {
  		width: 100%;
  		height: 514px;
  		background: url(../assets/Login/loginbg.png) no-repeat center top;
  		background-size: cover;

  		.input-box {
  			box-sizing: border-box;
  			width: 48%;
  			height: 100%;
  			float: right;
			.input-box-top {
				padding: 10px 80px 0 50px;
			}
			.loginForm{
				.el-form-item__content{
					position: relative;
					.icon{
						position: absolute;
						font-size: 23px;
						z-index: 999;
						top: 48%;
						transform: translateY(-50%);
						left: 5px;
					}
					.icon2{
						cursor: pointer;
						position: absolute;
						font-size: 23px;
						z-index: 999;
						top: 53%;
						transform: translateY(-50%);
						right: 3px;
						height: 34px;
					}
					.icon3 {
						font-size: 16px;
						padding: 0 10px 0 10px;
						background: #9bbed3;
						border-radius: 5px;
					}
					.icon4 {
						background: #c9c8c8;
						color: #9e9d9d;
						width: 50px;
					}
				}
				.titleTip {
					font-size: 14px;
					text-align: left;
					margin-top: 40px;
					margin-bottom: 10px;
				}
				.pawleft{
					text-align: left ;
					.forgetPwd {
						float: right;
						color: #F54752;
						cursor: pointer;
					}
				}
			}
  			.loginP {
  				font-size: 18px;
  				font-family: Microsoft YaHei;
  				color: #32C5FF;
  				line-height: 36px;
				font-weight: bold;
  				margin: 120px auto 20px;
  			}
			.p1 {
				text-align: left;
				padding-left: 112px;
			}
			.p2 {
				text-align: left;
				padding-left: 87px;
			}
			.bott {
				font-size: 15px;
				color: #2F374A;
				.bottSpan {
					color: #32C5FF;
					cursor: pointer;
				}

			}
			.bott0 {
				margin-top: 45px;
			}
			.bott2 {
				font-size: 12px;
			}
			.bott3 {
				margin-top: 10px;
			}
  			input::placeholder {
  				color: #000000;
  			}
			.verifi {
				font-size: 10px;
				.el-input__inner {
  				padding: 0 60px 0 35px;

  			}
			}
  			.el-input__inner {
  				border: none;
  				border-radius: 0;
  				// border-bottom: 1px solid #000000;
  				padding: 0 0 0 35px;
				background-color: #f4f4f8;
				border-radius: 5px;

  			}
			input::-webkit-input-placeholder{
                color:#acacac;
            }input::-moz-placeholder{   /* Mozilla Firefox 19+ */
                color:#acacac;
            }
	        input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
                color:#acacac;
            }
	        input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
                color:#acacac;
            }
  			.el-checkbox__inner {
  				border-radius: 0;
  			}

  			.el-button--primary {
  				width: 100%;
				background: #2F374A;
  				border-radius: 22px;
  				font-size: 18px;
  				margin: 20px auto 0px auto;
  			}
			.el-button--info {
  				width: 100%;
  				border-radius: 22px;
  				font-size: 18px;
  				margin: 20px auto 0px auto;
  			}

  		}
  	}
	.foot-container{
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2f374a;
		a{
				text-decoration: none
			}
		a:link {color:#2f374a;}    /* 未被访问的链接 */
		a:visited {color:#2f374a;} /* 已被访问的链接 */
		a:hover {color:#2f374a;}   /* 鼠标指针移动到链接上 */
		a:active {color:#2f374a;}  /* 正在被点击的链接 */
	}
  }
}

</style>
